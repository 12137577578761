.wave-image1 {
    background-image: url(../../../assets/Wave.svg);
    background-size: 120%;
    background-position-x: -70px;
    background-repeat: no-repeat;
   
}

.wave-image2 {
    background-image: url(../../../assets/Wave2.svg);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position-x: -60px;
   
}

.wave-image3{
    background-image: url(../../../assets/Wave3.svg);
    background-size: 120%;
    white-space: normal;
    background-repeat: no-repeat;
    background-position-x: -30px;
}
.wave-image4{
    background-image: url(../../../assets/Wave4.svg);
    background-size: 110%;
    white-space: normal;
    background-repeat: no-repeat;
    background-position-x: -80px;
}

.bg-image4{
    background-image: url(../../../assets/Rectangle3.svg);
    background-repeat: no-repeat;
}

.bg-1{
    background-image: linear-gradient(to right,rgb(99,199,247),rgb(198,159,189));
}

.bg-2{
    background-image: linear-gradient(to right,rgb(181,119,254),rgb(212,173,226));
}
.bg-3{
    background-image: linear-gradient(to right,rgb(96,166,185),rgb(226,132,149));
}

.bg-color{
    background-color: #1E1E1E;
}
