@font-face {
    font-family: "Halenoir";
    src: url(./Halenoir/Halenoir-Light.otf);
    font-style: normal;
    font-weight: 400;
}

// @font-face {
//     font-family: "Halenoir";
//     src: url(./Halenoir/Halenoir-Bold.otf);
//     font-style: normal;
//     font-weight: 600;
// }

//   @font-face {
//     font-family: "Halenoir";
//     src: url(./Halenoir/);
//     font-style: italic;
//     font-weight: 400;
//   }
// @font-face {
//     font-family: "Halenoir";
//     src: url(./Halenoir/Halenoir-Bold.otf);
//     font-style: normal;
//     font-weight: 700;
// }

//   @font-face {
//     font-family: "Halenoir";
//     src: url(./Gill-Sans-Nova-Book-Italic.ttf);
//     font-style: italic;
//     font-weight: 700;
//   }